/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
const TeacherDash = () => {
    return (
        <div className="main-wrapper">
            <div className="main-content">
            <h1 className="text-center">teacher deshboard data come soon !!!!!!</h1>
                <div className="d-flex justify-cantent-between">
                <div className="card m-2">
                    <div className="p-2">
                      
                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Officiis, natus dicta dignissimos necessitatibus dolor cum praesentium quibusdam eveniet ipsa doloribus nesciunt?
                            Molestias ratione in quae dolorum perspiciatis mollitia totam maxime! Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus animi quod repellendus asperiores!
                             Saepe aliquam totam architecto quae nam ducimus debitis maxime dolor aperiam assumenda. Hic quibusdam necessitatibus reprehenderit dolorum.</p>
                    </div>
                </div>
                <div className="card m-2">
                    <div className="p-2">
                       
                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Officiis, natus dicta dignissimos necessitatibus dolor cum praesentium quibusdam eveniet ipsa doloribus nesciunt?
                            Molestias ratione in quae dolorum perspiciatis mollitia totam maxime!</p>
                    </div>
                </div>
                </div>
                <h3 style={{ textAlign: 'center', marginBottom: '0px', color:"red" }}>Coming soon...........!!!!!!</h3>
            </div>
        </div>
    )

}

export default TeacherDash;