import React from "react";

function Profile() {
  const name = "atul yadav";

  return (
    <>
      <h1>I&apos;ve rendered {name} times!</h1>;
    </>
  );
}

export default Profile;
