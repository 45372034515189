import React from 'react'
import MainContent from '../../Components/MainContent/MainContent'


function Dashboard() {
    return (
        <>
            <MainContent /> 
        </>
    )
}

export default Dashboard
